import React from "react"
import { GatsbyLocation } from "local-types"
import SEO from "../components/Seo/Seo"
import styled from "styled-components"
import { device } from "../styles/constants"

import SectionLayout from "../components/WrapperSections/SectionLayout"

import { useSiteMetadata } from "../hooks"

const standartMetadata = {
  Title: "Flipbase",
  Keywords: ["Flipbase"],
  Description:
    "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology. Read more about Flipbase.",
  Image: "/seo-image-general.png",
}

interface ProcessingAgreementProps {
  location: GatsbyLocation
  preivousPath?: string
  nextPath?: string
}

const ProcessingAgreement: React.FunctionComponent<
  ProcessingAgreementProps
> = ({ location, preivousPath = "/blog" }): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata()
  return (
    <>
      <SEO
        title={standartMetadata.Title}
        description={standartMetadata.Description}
        keywords={standartMetadata.Keywords}
        image={standartMetadata.Image}
      />
      <SectionLayout>
        <WrapperContent>
          <h3>DATA PROCESSOR AGREEMENT</h3>
          <h5>The undersigned:</h5>
          <p>
            1. Customer, whose company and contact details and principal place
            of business are specified in the offer, hereinafter referred to as “
            <b>Controller</b>”
          </p>
          <p>and</p>
          <p>
            2. <b>Flipbase B.V.</b>, a private company with limited liability,
            incorporated and registered under the laws of the Netherlands having
            its registered office in (1018 CV) Amsterdam, at the Plantage
            Kerklaan 35 3-C, registered with the Chamber of Commerce of the
            Netherlands under number 69080860, in this matter duly represented
            by Bram Tierie (CCO) &amp; Ron Jansen (CTO), hereinafter referred to
            as to as “<b>Processor</b>”,
          </p>
          <p>
            Processor and Controller are hereinafter also referred to
            individually as “<b>Party”</b> or collectively as “<b>Parties</b>”
          </p>
          <h5>Whereas:</h5>
          <ul>
            <li>
              The Processor provides, for the benefit of the Controller, a video
              recording and playback functionality integrated into the
              recruitment process and system and a module with which Controller
              can produce, manage and publish video content;
            </li>
            <li>
              On signing date of this Processing Agreement document, the
              Controller and the Processor concluded an agreement regarding the
              provision of the aforementioned services, of which this
              Processor’s Agreement is a part;
            </li>
            <li>
              Where the personal data processing is concerned, the Controller
              classifies as a controller within the meaning of Section 4(7) of
              the General Data Protection Regulation (Algemene Verordening
              Gegevensbescherming) (“GDPR”);
            </li>
            <li>
              Where the personal data processing is concerned, the Processor
              qualifies as a processor within the meaning of Section 4(8) GDPR;
            </li>
            <li>
              The Parties - partly in implementation of the provisions of
              Section 28(3) GDPR- wish to document a number of conditions in the
              present processor’s agreement which apply to their relationship in
              the context of the aforesaid activities on the instructions and
              for the benefit of the Controller.
            </li>
          </ul>
          <h5>Declare that they have agreed as follows:</h5>
          <h5>Article 1. Definitions</h5>
          <p>
            1.1 In this Processor’s Agreement, capitalized words and
            expressions, whether in single or plural, have the meaning specified
            as set out below:Annex: appendix to this Processor’s Agreement which
            forms an integral part of it;
          </p>
          <p>
            <i>Agreement:</i> the agreement concluded between the Controller and
            the Processor with regarding the provision of services by Processor;
          </p>
          <p>
            <i>Personal Data:</i> all information relating to an identified or
            identifiable natural person as referred to in Section 4(1) GDPR;
          </p>
          <p>
            <i>Process:</i> as well as conjugations of this verb: the processing
            of Personal Data as referred to in Section 4(2) GDPR;
          </p>
          <p>
            <i>Processor’s Agreement:</i> the present agreement;
          </p>
          <p>
            <i>Sub Processor:</i> the sub-contractor hired by Processor, that
            Processes Personal Data in the context of this Processor’s Agreement
            on behalf of the Controller, as referred to in Section 28(4) GDPR;
          </p>
          <p>
            <i>Terms:</i> the terms of use of Processor, which form an integral
            part of the Agreement.
          </p>
          <p>
            1.2. The provisions of the Agreement apply in full to this
            Processor’s Agreement. In case provisions with regard to the
            Processing of Personal Data are included in the Agreement, the
            provisions of this Processor’s Agreement prevail.
          </p>
          <h5>Article 2. Purpose of the Personal Data Processing</h5>
          <p>
            2.1. The Controller and the Processor have concluded the present
            Processing Agreement for the Processing of Personal Data in the
            context of the Agreement. An overview of the type of Personal Data,
            categories of data subjects and the purposes of Processing, is
            included in <b>Annex 1.</b>
          </p>
          <p>
            2.2. The Controller is responsible and liable for the processing of
            Personal Data in relation to the Agreement and guarantees that
            Processing is in compliance with all applicable legislation.
            Controller will indemnify and hold harmless Processor against any
            and all claims of third parties, those of the data protection
            authority in particular, resulting in any way from not complying
            with this guarantee.
          </p>
          <p>
            2.3. The Processor undertakes to Process Personal Data only for the
            purpose of the activities referred to in this Processor’s Agreement.
            The Processor guarantees that it will not use the Personal Data
            which it Processes in the context of this Processor’s Agreement for
            its own or third-party purposes without the Controller’s express
            written consent, unless a legal provision requires the Processor to
            do so. In such case, the Processor shall immediately inform the
            Controller of that legal requirement before Processing, unless that
            law prohibits such information on import grounds of public interest.
          </p>
          <h5>Article 3. Technical and organizational provisions</h5>
          <p>
            3.1. The Processor will, taking into account the nature of the
            Processing and insofar as this is reasonable possible, assist the
            Controller in ensuring compliance with the obligations pursuant to
            the GDPR to take appropriate technical and organizational measures
            to ensure a level of security appropriate to the risk. These
            measures will guarantee an appropriate level of security, taking
            into account the state of the art and the costs of implementation,
            in view of the risks entailed by Personal Data Processing and the
            nature of the data to be protected. The Processor will in any case
            take measures to protect Personal Data against accidental or
            unlawful destruction, accidental or deliberate loss, forgery,
            unauthorized distribution or access, or any other form of unlawful
            Processing.
          </p>
          <p>
            3.2. Processor will provide a document which describes the
            appropriate technical and organizational measures to be taken by the
            Processor. This document will be attached to this Processor’s
            Agreement as <b>Annex 2.</b>
          </p>
          <h5>Article 4. Confidentiality</h5>
          <p>
            4.1. The Processor will require the employees that are involved in
            the execution of the Agreement to sign a confidentiality statement –
            whether or not included in the employment agreement with those
            employees – which in any case states that these employees must keep
            strict confidentiality regarding the Personal Data.
          </p>
          <h5>Article 5. Personal Data Processing outside Europe</h5>
          <p>
            5.1. The Processor will only be permitted to transfer Personal Data
            outside the European Economic Area if this is done in compliance
            with the applicable statutory obligations.
          </p>
          <h5>Article 6. Sub-processors</h5>
          <p>
            6.1. The Processor is entitled to outsource the implementation of
            the Processing on the Controller’s instructions to Sub-processors,
            either wholly or in part, which parties are described in{" "}
            <b>Annex 3.</b> In case the Processor wishes to enable
            Sub-processors, the Processor will inform Controller of any intended
            changes concerning the addition or replacement of other processors.
            The Controller will to object to such changes within 5 working days.
            The Processor will respond to the objection within 5 working days.
          </p>
          <p>
            6.2. Processor obligates each Sub-processors to contractually comply
            with the confidentiality obligations, notification obligations and
            security measures relating to the Processing of Personal Data, which
            obligations and measures must at least comply with the provisions of
            this Processor’s Agreement.
          </p>
          <h5>Article 7. Liability</h5>
          <p>
            7.1. With regard to the liability and indemnification obligations of
            Processor under this Processor’s Agreement the stipulation in
            Article 7 of the Terms regarding the limitation of liability
            applies.
          </p>
          <p>
            7.2. Without prejudice to article 7.1 of this Processor’s Agreement,
            Processor is solely liable for damages suffered by Controller and/or
            third party claims as a result of any Processing, in the event the
            specific obligations of Processor under the GDPR are not complied
            with or in case the Processor acted in violence of the legitimate
            instructions of the Controller.
          </p>
          <h5>Article 8. Personal Data Breach</h5>
          <p>
            8.1. In the event the Processor becomes aware of any incident that
            may have a (significant) impact on the protection of Personal Data,
            i) it will notify the Controller without undue delay and ii) will
            take all reasonable measures to prevent or limit (further) violation
            of the GDPR.{" "}
          </p>
          <p>
            8.2. The Processor will, insofar as reasonable, provide all
            reasonable cooperation requested by the Controller in order for
            Controller to comply with its legal obligations relating to the
            identified incident.
          </p>
          <p>
            8.3. The Processor will, insofar as reasonable, assist the
            Controller with the Controller’s notification obligation relating to
            the Personal Data to the Data Protection Authority and/or the data
            subject, as meant in Section 33(3) and 34(1) GDPR. Processor is
            never held to report a personal data breach with the Data Protection
            Authority and/or the data subject.
          </p>
          <p>
            8.4. Processor will not be responsible and/or liable for the (timely
            and correctly) notification obligation to the relevant supervisor
            and/or data subjects, as meant in Section 33 and 34 GDPR.
          </p>
          <h5>Article 9. Cooperation</h5>
          <p>
            9.1. The Processor will, insofar as reasonably possible, provide all
            reasonable cooperation to the Controller in fulfilling its
            obligation pursuant to the GDPR to respond to requests for
            exercising rights of data subjects, in particular the right of
            access (Section 15 GDPR), rectification (Section 16 GDPR), erasure
            (Section 17 GDPR), restriction (Section 18 GDPR), data portability
            (Section 20 GDPR) and the right to object (Section 21 and 22 GDPR).
            The Processor will forward a complaint or request from a data
            subject with regard to the Processing of Personal Data to the
            Controller as soon as possible, as the Controller is responsible for
            handling the request. The Processor is entitled to charge any costs
            associated with the cooperation with the Controller.
          </p>
          <p>
            9.2. The Processor will, insofar as reasonably possible, provide all
            reasonable cooperation to the Controller in fulfilling its
            obligation pursuant to the GDPR to carry out a data protection
            impact assessment (Section 35 and 36 GDPR).
          </p>
          <p>
            9.3. The Processor will provide the Controller with all the
            information reasonably necessary to demonstrate that the Processor
            fulfills its obligations under the GDPR. Furthermore, the Processor
            will – at the request of the Controller – enable and contribute to
            audits, including inspections by the Controller or an auditor that
            is authorized by the Controller. In case the Processor is of the
            opinion that an instruction relating to the provisions of this
            paragraph infringes the GDPR or other applicable data protection
            legislation, the Processor will inform the Controller immediately.
          </p>
          <p>
            9.4. The Processor is entitled to charge any possible costs with the
            Controller.
          </p>
          <h5>Article 10. Termination and miscellaneous</h5>
          <p>
            10.1. With regard to the termination under this Processor’s
            Agreement the specific provisions of the Agreement apply. Without
            prejudice to the specific provisions of the Agreement, the Processor
            will, at the first request of the Controller, delete or return all
            the Personal Data, and delete all existing copies, unless the
            Processor is legally required to store (part of) the Personal Data.
          </p>
          <p>
            10.2. The Controller will adequately inform the Processor about the
            (statutory) retention periods that apply to the Processing of
            Personal Data by the Processor.
          </p>
          <p>
            10.3. The obligations laid down in this Processor’s Agreement which,
            by their nature, are designed to continue after termination will
            remain in force also after the termination of this Processor’s
            Agreement.
          </p>
          <p>
            10.4. The choice of law and competent court comply with the
            applicable provisions of the Agreement.
          </p>
          <h4>ANNEX 1 OVERVIEW PERSONAL DATA</h4>
          <h5>TYPE OF PERSONAL DATA:</h5>
          <ul>
            <li>video</li>
            <li>email address</li>
            <li>name</li>
            <li>
              related metadata, among others, but not limited to candidate or
              colleague identifiers, location, vacancy title, job title and
              department.
            </li>
            <li>ip address</li>
          </ul>
          <h5>CATEGORIES OF DATA SUBJECTS:</h5>
          <p>
            In general the data subject is one or more individual(s) that can be
            identified in a video processed by Processor. More specifically, in
            case Controller uses Processors Candidate Screening product the data
            subject is a candidate, who has applied or wants to apply at
            Controllers organization or organization the Controller mediates
            for. In case Controller uses Processors Employer Branding product
            the data subject is an individual that wants to help Controller to
            promote one or more vacancies, or the Controllers organization as a
            whole.
          </p>
          <h5>PURPOSES OF PROCESSING:</h5>
          <ul>
            <li>
              To create multiple video formats available so users can playback
              them in all modern browsers
            </li>
            <li>
              To relate a submitted video with to an profile of an individual in
              an existing system, recruitment system or ERP system
            </li>
            <li>To be able to monitor and detect unauthorised attacks</li>
          </ul>
          <h4>ANNEX 2 SPECIFICATION OF THE SECURITY MEASURES</h4>
          <p>
            To shape information security according to multiple international
            standards we use the NEN-ISO/IEC 27001:2013 framework to create and
            evaluate the Security Management System. All information security
            implementations and measures are documented in the following
            documents:
          </p>
          <ul>
            <li>Risk assessment methodology</li>
            <li>Risk assessment;</li>
            <li>Subcontractors document;</li>
            <li>Privacy Impact Assessment;</li>
            <li>Data Classification;</li>
            <li>Security Policy;</li>
            <li>Security Policy Summary;</li>
            <li>Disaster recovery plan</li>
          </ul>
          <h5>Employees</h5>
          <p>
            This Security Policy document applies as a security agreement for
            employees of Processor. This document needs to be signed when
            employees start working at Processor. This document will be
            revisioned each year and therefore needs to be signed each year by
            all employees.
          </p>
          <p>
            Processors employees are required to perform Processor related tasks
            on the provided devices, which have some pre-configured and
            pre-installed security measures. All devices are required to be
            password protected; hard disks need to be encrypted and firewalls
            need to be enabled.
          </p>
          <h5>Management</h5>
          <p>
            To make sure information security and business continuity is managed
            now and in the future, the Processors managing board commits to
            annual evaluations, updates and acts appropriately upon the
            information security management system and information security
            implementation.
          </p>
          <p>
            This includes education and training of personnel, so they are able
            to handle sensitive information with care.
          </p>
          <h5>Password management</h5>
          <p>
            All passwords and credentials to third party systems and software
            need to be stored in a centralized password manager, which is
            accessible from the cloud. Employees of Processor are required to
            store all their passwords in 1Password manager. Additionally,
            specific password requirements apply, as defined in the security
            policy.
          </p>
          <h5>Suppliers &amp; subcontractors</h5>
          <p>
            Every external information system or subcontractor Processor uses is
            documented in the ‘Subcontractors’ document. In case a supplier or
            subcontractor has access to personal identifiable or sensitive data,
            Processor makes sure a processing agreement is signed with the
            supplier or vendor which is in line with Processors default
            processing agreement.
          </p>
          <h5>Cryptographic management</h5>
          <p>
            We use only proven cryptographic algorithms with recommended key
            sizes, like AES and SHA 256. All cryptographic keys are only stored
            in a single, password encrypted file. No credentials or passwords
            are stored in source code.
          </p>
          <h5>Data at rest &amp; data in transit</h5>
          <p>
            Data at rest, including video metadata and video content, are stored
            using AES 256 encryption. All data in transit is encrypted using
            latest version TLS with a strong cipher suite.
          </p>
          <h5>Cookies</h5>
          <p>
            Cookies are encrypted to prevent malicious content to view cookies
            and/or modify it so that the confidentiality and integrity of the
            content of cookies is guaranteed. Cookie attributes are set to
            'HttpOnly' and 'Secure' to prevent that cookie information can be
            intercepted.
          </p>
          <h5>Development</h5>
          <p>
            We use source code versioning to manage the development process.
            Automated unit- &amp; integration tests, static code analysis,
            linting tools and annual code reviews are used to maximize security.
          </p>
          <h5>Application security</h5>
          <p>
            The baseline of application security is based on OWASP
            implementation guidelines. All non-public REST API interfaces
            require an encrypted and validated customer bound signature to
            access or update a resource, so we can track 1) who is trying to
            access a resource and 2) can identify if this entity has the proper
            permissions to execute the specified action on the resource.{" "}
          </p>
          <h5>Monitoring</h5>
          <p>
            Servers are automatically and continuously monitored on, among
            others, but not limited to, CPU usage, memory usage, network
            activity and storage space. Applications are automatically and
            continuously monitored on, among others, but not limited to, request
            speed, request volume, errors and exceptions and crashes. If one
            metric exceeds a certain threshold an incident is triggered and
            reported automatically.
          </p>
          <h5>Logging</h5>
          <p>
            Processor uses a centralised log database where all log files and
            messages are send to. This centralised system enables Processor to
            efficiently analyse any issue, anywhere in the whole architecture in
            real time and identify unexpected behaviour.
          </p>
          <h5>Incident management</h5>
          <p>
            Any security incident can be reported by email by the Operations
            manager. Third parties can report incidents by sending an email to{" "}
            <a href="mailto:incidents@flipbase.com">incidents@flipbase.com</a>{" "}
            Reported incidents are classified and organized based upon the
            impact on the information security.
          </p>
          <h5>Disaster recovery</h5>
          <p>
            We have formalized a disaster recovery process that covers 1) a
            single data centre (and all related availability zones) not being
            accessible, 2) a single availability zone in a single data centre
            being done and 3) the related DNS servers being down.
          </p>
          <h5>ANNEX 3 OVERVIEW SUB PROCESSORS</h5>
          <table>
            <tbody>
              <tr>
                <td>Subcontractor</td>
                <td>Which data is being processed?</td>
                <td>In which countries is data being processed?</td>
                <td>Includes personal identifiable data?</td>
              </tr>
              <tr>
                <td>Brightcove</td>
                <td>
                  <p>
                    {" "}
                    Transcoding submitted video files into multiple formats that
                    are compatible for the web;{" "}
                  </p>
                  <p>
                    {" "}
                    Transferring transcoded video files to Amazon Web Services{" "}
                  </p>
                </td>
                <td>Dublin</td>
                <td>yes</td>
              </tr>
              <tr>
                <td>Amazon Web Services</td>
                <td>
                  <p> - Processing and storing video metadata;</p>
                  <p> - Storage of (transcoded) video files; </p>
                  <p>
                    {" "}
                    - Scanning content of video files on malicious content;{" "}
                  </p>
                  <p> - Serving video files to web clients</p>
                </td>
                <td>Frankfurt &amp; Dublin</td>
                <td>yes</td>
              </tr>
            </tbody>
          </table>
          <DownloadButton
            href="/Flipbase-Processing-agreement-1.pdf"
            target="_blank"
          >
            DOWNLOAD .PDF
          </DownloadButton>
        </WrapperContent>
      </SectionLayout>
    </>
  )
}

const WrapperContent = styled.div`
  font-family: FreightSans;
  color: #221e30;
  @media ${device.tablet} {
    margin: 0 32px;
  }
  @media ${device.mobileL} {
    margin: 0 16px;
  }
  table {
    margin: 64px 0;
    p {
      @media ${device.tablet} {
        font-size: 14px;
      }
    }
  }
  h3 {
    font-size: 28px;
    font-weight: 600;
    margin: 24px 0;
    line-height: 1.75;
  }
  h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 16px 0;
    line-height: 1.75;
  }
  h5 {
    font-size: 22px;
    font-weight: 600;
    margin: 16px 0;
    line-height: 1.75;
  }
  p,
  li,
  td {
    font-size: 20px;
    margin: 8px 0;
    line-height: 1.75;
  }
  td {
    font-weight: 600;
    padding: 24px 16px;
    @media ${device.tablet} {
      padding: 8px;
      font-size: 14px;
    }
  }
  a {
    color: #a62651;
    :hover {
      color: #a62651;
    }
  }
  margin-bottom: 64px;
`

const DownloadButton = styled.a`
  width: 100%;
  background-color: #221e30;
  color: #ffffff !important;
  display: block;
  text-shadow: none;
  text-align: center;
  font-size: 20px;
  padding: 16px 0;
  cursor: pointer !important;
`

export default ProcessingAgreement
