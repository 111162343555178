export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "1600px",
  desktopL: "2560px",
}

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
}

export const colors = {
  dark: "#231D30",
  gray: "#7B7783",
  lightGray: "#f1f3f4",
  mainColor: "#A62651",
  mainTransparent: "#D9B0BF",
  mainLight: "#F9F3F6",
  white: "#FFFFFF",
}
